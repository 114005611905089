@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&family=Londrina+Outline&family=Raleway:wght@100;300;400;500;600;700;800&display=swap');

body {
  @apply bg-primary w-full flex flex-col items-center;
  // cursor: url('/img/cursor-small.png'), auto !important;
  font-family: 'Raleway', sans-serif;
}

html.dark {
  body {
    @apply bg-primary;
    font-family: 'Raleway', sans-serif;
  }
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

.font-londrina {
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.03em;
}

.font-londrina-outline {
  font-family: 'Londrina Outline', sans-serif !important;
  letter-spacing: 0.03em;
}

.max-width-container {
  @apply w-full max-w-[1170px] px-4 md:px-6;
}

.floating-animation {
  animation: float 1.5s infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.show-from-above {
  animation: show 0.3s linear;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.disappear {
  animation: disappear 0.3s linear;
}

@keyframes disappear {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

h1 {
  @apply text-orange;
  font-size: 50px;
  line-height: 70px;
  font-family: 'Londrina Solid', sans-serif;
  font-family: Londrina Solid;
  letter-spacing: 0.03em;
}

h2 {
  @apply text-white;
  font-size: 80px;
  line-height: 100px;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.03em;
}

h3 {
  @apply text-white;
  font-size: 64px;
  line-height: 88px;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.03em;
}

h4 {
  @apply text-white;
  font-size: 48px;
  line-height: 64px;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.03em;
}

h5 {
  @apply text-white;
  font-size: 40px;
  line-height: 48px;
  font-family: 'Londrina Solid', sans-serif;
  letter-spacing: 0.03em;
}

p {
  @apply text-white;
  font-size: 32px;
  line-height: 50px;
}

@media screen and (max-width: 1099px) {
  h1 {
    @apply text-white;
    font-size: 112px;
    line-height: 160px;
  }

  h2 {
    @apply text-white;
    font-size: 64px;
    line-height: 88px;
  }

  h3 {
    @apply text-white;
    font-size: 40px;
    line-height: 48px;
  }

  h4 {
    @apply text-white;
    font-size: 32px;
    line-height: 40px;
  }

  h5 {
    font-size: 24px;
    line-height: 48px;
  }
  p {
    @apply text-white;
    font-size: 24px;
    line-height: 48px;
  }
}

@media screen and (max-width: 755px) {
  h1 {
    @apply text-white;
    font-size: 64px;
    line-height: 88px;
  }

  h2 {
    @apply text-white;
    font-size: 48px;
    line-height: 64px;
  }

  h3 {
    @apply text-white;
    font-size: 40px;
    line-height: 48px;
  }

  h4 {
    @apply text-white;
    font-size: 32px;
    line-height: 40px;
  }

  h5 {
    font-size: 24px;
    line-height: 48px;
  }
  p {
    @apply text-white;
    font-size: 24px;
    line-height: 48px;
  }
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
